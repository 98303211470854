/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter long_type_string,client_generic,server_none,eslint_disable
// @generated from protobuf file "video/sfu/models/models.proto" (package "stream.video.sfu.models", syntax proto3)
// tslint:disable
import type {
  BinaryReadOptions,
  BinaryWriteOptions,
  IBinaryReader,
  IBinaryWriter,
  PartialMessage,
} from '@protobuf-ts/runtime';
import {
  MessageType,
  reflectionMergePartial,
  UnknownFieldHandler,
  WireType,
} from '@protobuf-ts/runtime';
import { Struct } from '../../../google/protobuf/struct';
import { Timestamp } from '../../../google/protobuf/timestamp';

/**
 * CallState is the current state of the call
 * as seen by an SFU.
 *
 * @generated from protobuf message stream.video.sfu.models.CallState
 */
export interface CallState {
  /**
   * participants is the list of participants in the call.
   * In large calls, the list could be truncated in which
   * case, the list of participants contains fewer participants
   * than the counts returned in participant_count. Anonymous
   * participants are **NOT** included in the list.
   *
   * @generated from protobuf field: repeated stream.video.sfu.models.Participant participants = 1;
   */
  participants: Participant[];
  /**
   * started_at is the time the call session actually started.
   *
   * @generated from protobuf field: google.protobuf.Timestamp started_at = 2;
   */
  startedAt?: Timestamp;
  /**
   * participant_count contains the summary of the counts.
   *
   * @generated from protobuf field: stream.video.sfu.models.ParticipantCount participant_count = 3;
   */
  participantCount?: ParticipantCount;
  /**
   * the list of pins in the call.
   * Pins are ordered in descending order (most important first).
   *
   * @generated from protobuf field: repeated stream.video.sfu.models.Pin pins = 4;
   */
  pins: Pin[];
}
/**
 * @generated from protobuf message stream.video.sfu.models.ParticipantCount
 */
export interface ParticipantCount {
  /**
   * Total number of participants in the call including
   * the anonymous participants.
   *
   * @generated from protobuf field: uint32 total = 1;
   */
  total: number;
  /**
   * Total number of anonymous participants in the call.
   *
   * @generated from protobuf field: uint32 anonymous = 2;
   */
  anonymous: number;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Pin
 */
export interface Pin {
  /**
   * the user to pin
   *
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * the user sesion_id to pin, if not provided, applies to all sessions
   *
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
}
/**
 * those who are online in the call
 *
 * @generated from protobuf message stream.video.sfu.models.Participant
 */
export interface Participant {
  /**
   * @generated from protobuf field: string user_id = 1;
   */
  userId: string;
  /**
   * @generated from protobuf field: string session_id = 2;
   */
  sessionId: string;
  /**
   * map of track id to track type
   *
   * @generated from protobuf field: repeated stream.video.sfu.models.TrackType published_tracks = 3;
   */
  publishedTracks: TrackType[];
  /**
   * @generated from protobuf field: google.protobuf.Timestamp joined_at = 4;
   */
  joinedAt?: Timestamp;
  /**
   * @generated from protobuf field: string track_lookup_prefix = 5;
   */
  trackLookupPrefix: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.ConnectionQuality connection_quality = 6;
   */
  connectionQuality: ConnectionQuality;
  /**
   * @generated from protobuf field: bool is_speaking = 7;
   */
  isSpeaking: boolean;
  /**
   * @generated from protobuf field: bool is_dominant_speaker = 8;
   */
  isDominantSpeaker: boolean;
  /**
   * @generated from protobuf field: float audio_level = 9;
   */
  audioLevel: number;
  /**
   * @generated from protobuf field: string name = 10;
   */
  name: string;
  /**
   * @generated from protobuf field: string image = 11;
   */
  image: string;
  /**
   * @generated from protobuf field: google.protobuf.Struct custom = 12;
   */
  custom?: Struct;
  /**
   * @generated from protobuf field: repeated string roles = 13;
   */
  roles: string[];
}
/**
 * @generated from protobuf message stream.video.sfu.models.StreamQuality
 */
export interface StreamQuality {
  /**
   * @generated from protobuf field: stream.video.sfu.models.VideoQuality video_quality = 1;
   */
  videoQuality: VideoQuality;
  /**
   * @generated from protobuf field: string user_id = 2;
   */
  userId: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.VideoDimension
 */
export interface VideoDimension {
  /**
   * @generated from protobuf field: uint32 width = 1;
   */
  width: number;
  /**
   * @generated from protobuf field: uint32 height = 2;
   */
  height: number;
}
/**
 * @generated from protobuf message stream.video.sfu.models.VideoLayer
 */
export interface VideoLayer {
  /**
   * for tracks with a single layer, this should be HIGH
   *
   * @generated from protobuf field: string rid = 1;
   */
  rid: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.VideoDimension video_dimension = 2;
   */
  videoDimension?: VideoDimension;
  /**
   * target bitrate, server will measure actual
   *
   * @generated from protobuf field: uint32 bitrate = 4;
   */
  bitrate: number;
  /**
   * @generated from protobuf field: uint32 fps = 5;
   */
  fps: number;
  /**
   * @generated from protobuf field: stream.video.sfu.models.VideoQuality quality = 6;
   */
  quality: VideoQuality;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Codec
 */
export interface Codec {
  /**
   * @generated from protobuf field: uint32 payload_type = 1;
   */
  payloadType: number;
  /**
   * @generated from protobuf field: string name = 2;
   */
  name: string;
  /**
   * @generated from protobuf field: string fmtp_line = 3;
   */
  fmtpLine: string;
  /**
   * @generated from protobuf field: uint32 clock_rate = 4;
   */
  clockRate: number;
  /**
   * @generated from protobuf field: string encoding_parameters = 5;
   */
  encodingParameters: string;
  /**
   * @generated from protobuf field: repeated string feedbacks = 6;
   */
  feedbacks: string[];
}
/**
 * @generated from protobuf message stream.video.sfu.models.ICETrickle
 */
export interface ICETrickle {
  /**
   * @generated from protobuf field: stream.video.sfu.models.PeerType peer_type = 1;
   */
  peerType: PeerType;
  /**
   * @generated from protobuf field: string ice_candidate = 2;
   */
  iceCandidate: string;
  /**
   * @generated from protobuf field: string session_id = 3;
   */
  sessionId: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.TrackInfo
 */
export interface TrackInfo {
  /**
   * @generated from protobuf field: string track_id = 1;
   */
  trackId: string;
  /**
   * @generated from protobuf field: stream.video.sfu.models.TrackType track_type = 2;
   */
  trackType: TrackType;
  /**
   * @generated from protobuf field: repeated stream.video.sfu.models.VideoLayer layers = 5;
   */
  layers: VideoLayer[];
  /**
   * @generated from protobuf field: string mid = 6;
   */
  mid: string;
  /**
   * for audio tracks
   *
   * @generated from protobuf field: bool dtx = 7;
   */
  dtx: boolean;
  /**
   * @generated from protobuf field: bool stereo = 8;
   */
  stereo: boolean;
  /**
   * @generated from protobuf field: bool red = 9;
   */
  red: boolean;
  /**
   * @generated from protobuf field: bool muted = 10;
   */
  muted: boolean;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Error
 */
export interface Error {
  /**
   * @generated from protobuf field: stream.video.sfu.models.ErrorCode code = 1;
   */
  code: ErrorCode;
  /**
   * @generated from protobuf field: string message = 2;
   */
  message: string;
  /**
   * @generated from protobuf field: bool should_retry = 3;
   */
  shouldRetry: boolean;
}
/**
 * @generated from protobuf message stream.video.sfu.models.ClientDetails
 */
export interface ClientDetails {
  /**
   * @generated from protobuf field: stream.video.sfu.models.Sdk sdk = 1;
   */
  sdk?: Sdk;
  /**
   * @generated from protobuf field: stream.video.sfu.models.OS os = 2;
   */
  os?: OS;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Browser browser = 3;
   */
  browser?: Browser;
  /**
   * @generated from protobuf field: stream.video.sfu.models.Device device = 4;
   */
  device?: Device;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Sdk
 */
export interface Sdk {
  /**
   * @generated from protobuf field: stream.video.sfu.models.SdkType type = 1;
   */
  type: SdkType;
  /**
   * @generated from protobuf field: string major = 2;
   */
  major: string;
  /**
   * @generated from protobuf field: string minor = 3;
   */
  minor: string;
  /**
   * @generated from protobuf field: string patch = 4;
   */
  patch: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.OS
 */
export interface OS {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string version = 2;
   */
  version: string;
  /**
   * @generated from protobuf field: string architecture = 3;
   */
  architecture: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Browser
 */
export interface Browser {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string version = 2;
   */
  version: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Device
 */
export interface Device {
  /**
   * @generated from protobuf field: string name = 1;
   */
  name: string;
  /**
   * @generated from protobuf field: string version = 2;
   */
  version: string;
}
/**
 * @generated from protobuf message stream.video.sfu.models.Call
 */
export interface Call {
  /**
   * the call type
   *
   * @generated from protobuf field: string type = 1;
   */
  type: string;
  /**
   * the call id
   *
   * @generated from protobuf field: string id = 2;
   */
  id: string;
  /**
   * the id of the user that created this call
   *
   * @generated from protobuf field: string created_by_user_id = 3;
   */
  createdByUserId: string;
  /**
   * the id of the current host for this call
   *
   * @generated from protobuf field: string host_user_id = 4;
   */
  hostUserId: string;
  /**
   * @generated from protobuf field: google.protobuf.Struct custom = 5;
   */
  custom?: Struct;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp created_at = 6;
   */
  createdAt?: Timestamp;
  /**
   * @generated from protobuf field: google.protobuf.Timestamp updated_at = 7;
   */
  updatedAt?: Timestamp;
}
/**
 * CallGrants represents the set of permissions given
 * to the user for the current call.
 *
 * @generated from protobuf message stream.video.sfu.models.CallGrants
 */
export interface CallGrants {
  /**
   * @generated from protobuf field: bool can_publish_audio = 1;
   */
  canPublishAudio: boolean;
  /**
   * @generated from protobuf field: bool can_publish_video = 2;
   */
  canPublishVideo: boolean;
  /**
   * @generated from protobuf field: bool can_screenshare = 3;
   */
  canScreenshare: boolean;
}
/**
 * @generated from protobuf enum stream.video.sfu.models.PeerType
 */
export enum PeerType {
  /**
   * todo fix me (marcelo)
   *
   * @generated from protobuf enum value: PEER_TYPE_PUBLISHER_UNSPECIFIED = 0;
   */
  PUBLISHER_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: PEER_TYPE_SUBSCRIBER = 1;
   */
  SUBSCRIBER = 1,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.ConnectionQuality
 */
export enum ConnectionQuality {
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_POOR = 1;
   */
  POOR = 1,
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_GOOD = 2;
   */
  GOOD = 2,
  /**
   * @generated from protobuf enum value: CONNECTION_QUALITY_EXCELLENT = 3;
   */
  EXCELLENT = 3,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.VideoQuality
 */
export enum VideoQuality {
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_LOW_UNSPECIFIED = 0;
   */
  LOW_UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_MID = 1;
   */
  MID = 1,
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_HIGH = 2;
   */
  HIGH = 2,
  /**
   * @generated from protobuf enum value: VIDEO_QUALITY_OFF = 3;
   */
  OFF = 3,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.TrackType
 */
export enum TrackType {
  /**
   * @generated from protobuf enum value: TRACK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_AUDIO = 1;
   */
  AUDIO = 1,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_VIDEO = 2;
   */
  VIDEO = 2,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_SCREEN_SHARE = 3;
   */
  SCREEN_SHARE = 3,
  /**
   * @generated from protobuf enum value: TRACK_TYPE_SCREEN_SHARE_AUDIO = 4;
   */
  SCREEN_SHARE_AUDIO = 4,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.ErrorCode
 */
export enum ErrorCode {
  /**
   * @generated from protobuf enum value: ERROR_CODE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACK_NOT_FOUND = 100;
   */
  PUBLISH_TRACK_NOT_FOUND = 100,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACKS_MISMATCH = 101;
   */
  PUBLISH_TRACKS_MISMATCH = 101,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACK_OUT_OF_ORDER = 102;
   */
  PUBLISH_TRACK_OUT_OF_ORDER = 102,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PUBLISH_TRACK_VIDEO_LAYER_NOT_FOUND = 103;
   */
  PUBLISH_TRACK_VIDEO_LAYER_NOT_FOUND = 103,
  /**
   * @generated from protobuf enum value: ERROR_CODE_LIVE_ENDED = 104;
   */
  LIVE_ENDED = 104,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_NOT_FOUND = 200;
   */
  PARTICIPANT_NOT_FOUND = 200,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MIGRATING_OUT = 201;
   */
  PARTICIPANT_MIGRATING_OUT = 201,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MIGRATION_FAILED = 202;
   */
  PARTICIPANT_MIGRATION_FAILED = 202,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MIGRATING = 203;
   */
  PARTICIPANT_MIGRATING = 203,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_RECONNECT_FAILED = 204;
   */
  PARTICIPANT_RECONNECT_FAILED = 204,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PARTICIPANT_MEDIA_TRANSPORT_FAILURE = 205;
   */
  PARTICIPANT_MEDIA_TRANSPORT_FAILURE = 205,
  /**
   * @generated from protobuf enum value: ERROR_CODE_CALL_NOT_FOUND = 300;
   */
  CALL_NOT_FOUND = 300,
  /**
   * @generated from protobuf enum value: ERROR_CODE_REQUEST_VALIDATION_FAILED = 400;
   */
  REQUEST_VALIDATION_FAILED = 400,
  /**
   * @generated from protobuf enum value: ERROR_CODE_UNAUTHENTICATED = 401;
   */
  UNAUTHENTICATED = 401,
  /**
   * @generated from protobuf enum value: ERROR_CODE_PERMISSION_DENIED = 403;
   */
  PERMISSION_DENIED = 403,
  /**
   * @generated from protobuf enum value: ERROR_CODE_TOO_MANY_REQUESTS = 429;
   */
  TOO_MANY_REQUESTS = 429,
  /**
   * @generated from protobuf enum value: ERROR_CODE_INTERNAL_SERVER_ERROR = 500;
   */
  INTERNAL_SERVER_ERROR = 500,
  /**
   * @generated from protobuf enum value: ERROR_CODE_SFU_SHUTTING_DOWN = 600;
   */
  SFU_SHUTTING_DOWN = 600,
  /**
   * @generated from protobuf enum value: ERROR_CODE_SFU_FULL = 700;
   */
  SFU_FULL = 700,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.SdkType
 */
export enum SdkType {
  /**
   * @generated from protobuf enum value: SDK_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: SDK_TYPE_REACT = 1;
   */
  REACT = 1,
  /**
   * @generated from protobuf enum value: SDK_TYPE_ANGULAR = 2;
   */
  ANGULAR = 2,
  /**
   * @generated from protobuf enum value: SDK_TYPE_ANDROID = 3;
   */
  ANDROID = 3,
  /**
   * @generated from protobuf enum value: SDK_TYPE_IOS = 4;
   */
  IOS = 4,
  /**
   * @generated from protobuf enum value: SDK_TYPE_FLUTTER = 5;
   */
  FLUTTER = 5,
  /**
   * @generated from protobuf enum value: SDK_TYPE_REACT_NATIVE = 6;
   */
  REACT_NATIVE = 6,
  /**
   * @generated from protobuf enum value: SDK_TYPE_UNITY = 7;
   */
  UNITY = 7,
  /**
   * @generated from protobuf enum value: SDK_TYPE_GO = 8;
   */
  GO = 8,
  /**
   * @generated from protobuf enum value: SDK_TYPE_PLAIN_JAVASCRIPT = 9;
   */
  PLAIN_JAVASCRIPT = 9,
}
/**
 * @generated from protobuf enum stream.video.sfu.models.TrackUnpublishReason
 */
export enum TrackUnpublishReason {
  /**
   * Default value which is used when the specific reason
   * for muting the track is not known.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Represents user muting their tracks.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_USER_MUTED = 1;
   */
  USER_MUTED = 1,
  /**
   * Represents muting the track because the permission to
   * publish the track has been revoked.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_PERMISSION_REVOKED = 2;
   */
  PERMISSION_REVOKED = 2,
  /**
   * Represents muting the track due to moderation actions.
   * This is different from permission revoked because the
   * participant can unmute themselves here whereas in case
   * of "permission revoke" it is not possible until the
   * call permissions are updated.
   *
   * @generated from protobuf enum value: TRACK_UNPUBLISH_REASON_MODERATION = 3;
   */
  MODERATION = 3,
}
/**
 * GoAwayReason represents the reason for the SFU to
 * disconnect the client.
 *
 * @generated from protobuf enum stream.video.sfu.models.GoAwayReason
 */
export enum GoAwayReason {
  /**
   * @generated from protobuf enum value: GO_AWAY_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: GO_AWAY_REASON_SHUTTING_DOWN = 1;
   */
  SHUTTING_DOWN = 1,
  /**
   * @generated from protobuf enum value: GO_AWAY_REASON_REBALANCE = 2;
   */
  REBALANCE = 2,
}
/**
 * CallEndedReason represents the reason for the call to end.
 *
 * @generated from protobuf enum stream.video.sfu.models.CallEndedReason
 */
export enum CallEndedReason {
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_ENDED = 1;
   */
  ENDED = 1,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_LIVE_ENDED = 2;
   */
  LIVE_ENDED = 2,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_KICKED = 3;
   */
  KICKED = 3,
  /**
   * @generated from protobuf enum value: CALL_ENDED_REASON_SESSION_ENDED = 4;
   */
  SESSION_ENDED = 4,
}
/**
 * WebsocketReconnectStrategy defines the ws strategies available for handling reconnections.
 *
 * @generated from protobuf enum stream.video.sfu.models.WebsocketReconnectStrategy
 */
export enum WebsocketReconnectStrategy {
  /**
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,
  /**
   * Sent after reaching the maximum reconnection attempts, or any other unrecoverable error leading to permanent disconnect.
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_DISCONNECT = 1;
   */
  DISCONNECT = 1,
  /**
   * SDK should maintaining existing publisher/subscriber pc instances
   * and establish a new WebSocket connection.
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_FAST = 2;
   */
  FAST = 2,
  /**
   * SDK should obtain new credentials from the coordinator, drops existing pc instances, set a new session_id and initializes
   * a completely new WebSocket connection, ensuring a comprehensive reset.
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_REJOIN = 3;
   */
  REJOIN = 3,
  /**
   * SDK should migrate to a new SFU instance
   *
   * @generated from protobuf enum value: WEBSOCKET_RECONNECT_STRATEGY_MIGRATE = 4;
   */
  MIGRATE = 4,
}
// @generated message type with reflection information, may provide speed optimized methods
class CallState$Type extends MessageType<CallState> {
  constructor() {
    super('stream.video.sfu.models.CallState', [
      {
        no: 1,
        name: 'participants',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Participant,
      },
      { no: 2, name: 'started_at', kind: 'message', T: () => Timestamp },
      {
        no: 3,
        name: 'participant_count',
        kind: 'message',
        T: () => ParticipantCount,
      },
      {
        no: 4,
        name: 'pins',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => Pin,
      },
    ]);
  }
  create(value?: PartialMessage<CallState>): CallState {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.participants = [];
    message.pins = [];
    if (value !== undefined)
      reflectionMergePartial<CallState>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CallState,
  ): CallState {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* repeated stream.video.sfu.models.Participant participants */ 1:
          message.participants.push(
            Participant.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* google.protobuf.Timestamp started_at */ 2:
          message.startedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.startedAt,
          );
          break;
        case /* stream.video.sfu.models.ParticipantCount participant_count */ 3:
          message.participantCount = ParticipantCount.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.participantCount,
          );
          break;
        case /* repeated stream.video.sfu.models.Pin pins */ 4:
          message.pins.push(
            Pin.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CallState,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* repeated stream.video.sfu.models.Participant participants = 1; */
    for (let i = 0; i < message.participants.length; i++)
      Participant.internalBinaryWrite(
        message.participants[i],
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp started_at = 2; */
    if (message.startedAt)
      Timestamp.internalBinaryWrite(
        message.startedAt,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* stream.video.sfu.models.ParticipantCount participant_count = 3; */
    if (message.participantCount)
      ParticipantCount.internalBinaryWrite(
        message.participantCount,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated stream.video.sfu.models.Pin pins = 4; */
    for (let i = 0; i < message.pins.length; i++)
      Pin.internalBinaryWrite(
        message.pins[i],
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.CallState
 */
export const CallState = new CallState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ParticipantCount$Type extends MessageType<ParticipantCount> {
  constructor() {
    super('stream.video.sfu.models.ParticipantCount', [
      { no: 1, name: 'total', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 2, name: 'anonymous', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
    ]);
  }
  create(value?: PartialMessage<ParticipantCount>): ParticipantCount {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.total = 0;
    message.anonymous = 0;
    if (value !== undefined)
      reflectionMergePartial<ParticipantCount>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ParticipantCount,
  ): ParticipantCount {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* uint32 total */ 1:
          message.total = reader.uint32();
          break;
        case /* uint32 anonymous */ 2:
          message.anonymous = reader.uint32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ParticipantCount,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* uint32 total = 1; */
    if (message.total !== 0)
      writer.tag(1, WireType.Varint).uint32(message.total);
    /* uint32 anonymous = 2; */
    if (message.anonymous !== 0)
      writer.tag(2, WireType.Varint).uint32(message.anonymous);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.ParticipantCount
 */
export const ParticipantCount = new ParticipantCount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Pin$Type extends MessageType<Pin> {
  constructor() {
    super('stream.video.sfu.models.Pin', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<Pin>): Pin {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.userId = '';
    message.sessionId = '';
    if (value !== undefined) reflectionMergePartial<Pin>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Pin,
  ): Pin {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string user_id */ 1:
          message.userId = reader.string();
          break;
        case /* string session_id */ 2:
          message.sessionId = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Pin,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string user_id = 1; */
    if (message.userId !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.userId);
    /* string session_id = 2; */
    if (message.sessionId !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.sessionId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Pin
 */
export const Pin = new Pin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Participant$Type extends MessageType<Participant> {
  constructor() {
    super('stream.video.sfu.models.Participant', [
      { no: 1, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'published_tracks',
        kind: 'enum',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => [
          'stream.video.sfu.models.TrackType',
          TrackType,
          'TRACK_TYPE_',
        ],
      },
      { no: 4, name: 'joined_at', kind: 'message', T: () => Timestamp },
      {
        no: 5,
        name: 'track_lookup_prefix',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'connection_quality',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.ConnectionQuality',
          ConnectionQuality,
          'CONNECTION_QUALITY_',
        ],
      },
      { no: 7, name: 'is_speaking', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      {
        no: 8,
        name: 'is_dominant_speaker',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      { no: 9, name: 'audio_level', kind: 'scalar', T: 2 /*ScalarType.FLOAT*/ },
      { no: 10, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 11, name: 'image', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 12, name: 'custom', kind: 'message', T: () => Struct },
      {
        no: 13,
        name: 'roles',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<Participant>): Participant {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.userId = '';
    message.sessionId = '';
    message.publishedTracks = [];
    message.trackLookupPrefix = '';
    message.connectionQuality = 0;
    message.isSpeaking = false;
    message.isDominantSpeaker = false;
    message.audioLevel = 0;
    message.name = '';
    message.image = '';
    message.roles = [];
    if (value !== undefined)
      reflectionMergePartial<Participant>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Participant,
  ): Participant {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string user_id */ 1:
          message.userId = reader.string();
          break;
        case /* string session_id */ 2:
          message.sessionId = reader.string();
          break;
        case /* repeated stream.video.sfu.models.TrackType published_tracks */ 3:
          if (wireType === WireType.LengthDelimited)
            for (let e = reader.int32() + reader.pos; reader.pos < e; )
              message.publishedTracks.push(reader.int32());
          else message.publishedTracks.push(reader.int32());
          break;
        case /* google.protobuf.Timestamp joined_at */ 4:
          message.joinedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.joinedAt,
          );
          break;
        case /* string track_lookup_prefix */ 5:
          message.trackLookupPrefix = reader.string();
          break;
        case /* stream.video.sfu.models.ConnectionQuality connection_quality */ 6:
          message.connectionQuality = reader.int32();
          break;
        case /* bool is_speaking */ 7:
          message.isSpeaking = reader.bool();
          break;
        case /* bool is_dominant_speaker */ 8:
          message.isDominantSpeaker = reader.bool();
          break;
        case /* float audio_level */ 9:
          message.audioLevel = reader.float();
          break;
        case /* string name */ 10:
          message.name = reader.string();
          break;
        case /* string image */ 11:
          message.image = reader.string();
          break;
        case /* google.protobuf.Struct custom */ 12:
          message.custom = Struct.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.custom,
          );
          break;
        case /* repeated string roles */ 13:
          message.roles.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Participant,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string user_id = 1; */
    if (message.userId !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.userId);
    /* string session_id = 2; */
    if (message.sessionId !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.sessionId);
    /* repeated stream.video.sfu.models.TrackType published_tracks = 3; */
    if (message.publishedTracks.length) {
      writer.tag(3, WireType.LengthDelimited).fork();
      for (let i = 0; i < message.publishedTracks.length; i++)
        writer.int32(message.publishedTracks[i]);
      writer.join();
    }
    /* google.protobuf.Timestamp joined_at = 4; */
    if (message.joinedAt)
      Timestamp.internalBinaryWrite(
        message.joinedAt,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string track_lookup_prefix = 5; */
    if (message.trackLookupPrefix !== '')
      writer.tag(5, WireType.LengthDelimited).string(message.trackLookupPrefix);
    /* stream.video.sfu.models.ConnectionQuality connection_quality = 6; */
    if (message.connectionQuality !== 0)
      writer.tag(6, WireType.Varint).int32(message.connectionQuality);
    /* bool is_speaking = 7; */
    if (message.isSpeaking !== false)
      writer.tag(7, WireType.Varint).bool(message.isSpeaking);
    /* bool is_dominant_speaker = 8; */
    if (message.isDominantSpeaker !== false)
      writer.tag(8, WireType.Varint).bool(message.isDominantSpeaker);
    /* float audio_level = 9; */
    if (message.audioLevel !== 0)
      writer.tag(9, WireType.Bit32).float(message.audioLevel);
    /* string name = 10; */
    if (message.name !== '')
      writer.tag(10, WireType.LengthDelimited).string(message.name);
    /* string image = 11; */
    if (message.image !== '')
      writer.tag(11, WireType.LengthDelimited).string(message.image);
    /* google.protobuf.Struct custom = 12; */
    if (message.custom)
      Struct.internalBinaryWrite(
        message.custom,
        writer.tag(12, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* repeated string roles = 13; */
    for (let i = 0; i < message.roles.length; i++)
      writer.tag(13, WireType.LengthDelimited).string(message.roles[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Participant
 */
export const Participant = new Participant$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StreamQuality$Type extends MessageType<StreamQuality> {
  constructor() {
    super('stream.video.sfu.models.StreamQuality', [
      {
        no: 1,
        name: 'video_quality',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.VideoQuality',
          VideoQuality,
          'VIDEO_QUALITY_',
        ],
      },
      { no: 2, name: 'user_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<StreamQuality>): StreamQuality {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.videoQuality = 0;
    message.userId = '';
    if (value !== undefined)
      reflectionMergePartial<StreamQuality>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: StreamQuality,
  ): StreamQuality {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* stream.video.sfu.models.VideoQuality video_quality */ 1:
          message.videoQuality = reader.int32();
          break;
        case /* string user_id */ 2:
          message.userId = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: StreamQuality,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* stream.video.sfu.models.VideoQuality video_quality = 1; */
    if (message.videoQuality !== 0)
      writer.tag(1, WireType.Varint).int32(message.videoQuality);
    /* string user_id = 2; */
    if (message.userId !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.userId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.StreamQuality
 */
export const StreamQuality = new StreamQuality$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoDimension$Type extends MessageType<VideoDimension> {
  constructor() {
    super('stream.video.sfu.models.VideoDimension', [
      { no: 1, name: 'width', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 2, name: 'height', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
    ]);
  }
  create(value?: PartialMessage<VideoDimension>): VideoDimension {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.width = 0;
    message.height = 0;
    if (value !== undefined)
      reflectionMergePartial<VideoDimension>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: VideoDimension,
  ): VideoDimension {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* uint32 width */ 1:
          message.width = reader.uint32();
          break;
        case /* uint32 height */ 2:
          message.height = reader.uint32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: VideoDimension,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* uint32 width = 1; */
    if (message.width !== 0)
      writer.tag(1, WireType.Varint).uint32(message.width);
    /* uint32 height = 2; */
    if (message.height !== 0)
      writer.tag(2, WireType.Varint).uint32(message.height);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.VideoDimension
 */
export const VideoDimension = new VideoDimension$Type();
// @generated message type with reflection information, may provide speed optimized methods
class VideoLayer$Type extends MessageType<VideoLayer> {
  constructor() {
    super('stream.video.sfu.models.VideoLayer', [
      { no: 1, name: 'rid', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'video_dimension',
        kind: 'message',
        T: () => VideoDimension,
      },
      { no: 4, name: 'bitrate', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      { no: 5, name: 'fps', kind: 'scalar', T: 13 /*ScalarType.UINT32*/ },
      {
        no: 6,
        name: 'quality',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.VideoQuality',
          VideoQuality,
          'VIDEO_QUALITY_',
        ],
      },
    ]);
  }
  create(value?: PartialMessage<VideoLayer>): VideoLayer {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.rid = '';
    message.bitrate = 0;
    message.fps = 0;
    message.quality = 0;
    if (value !== undefined)
      reflectionMergePartial<VideoLayer>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: VideoLayer,
  ): VideoLayer {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string rid */ 1:
          message.rid = reader.string();
          break;
        case /* stream.video.sfu.models.VideoDimension video_dimension */ 2:
          message.videoDimension = VideoDimension.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.videoDimension,
          );
          break;
        case /* uint32 bitrate */ 4:
          message.bitrate = reader.uint32();
          break;
        case /* uint32 fps */ 5:
          message.fps = reader.uint32();
          break;
        case /* stream.video.sfu.models.VideoQuality quality */ 6:
          message.quality = reader.int32();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: VideoLayer,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string rid = 1; */
    if (message.rid !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.rid);
    /* stream.video.sfu.models.VideoDimension video_dimension = 2; */
    if (message.videoDimension)
      VideoDimension.internalBinaryWrite(
        message.videoDimension,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* uint32 bitrate = 4; */
    if (message.bitrate !== 0)
      writer.tag(4, WireType.Varint).uint32(message.bitrate);
    /* uint32 fps = 5; */
    if (message.fps !== 0) writer.tag(5, WireType.Varint).uint32(message.fps);
    /* stream.video.sfu.models.VideoQuality quality = 6; */
    if (message.quality !== 0)
      writer.tag(6, WireType.Varint).int32(message.quality);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.VideoLayer
 */
export const VideoLayer = new VideoLayer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Codec$Type extends MessageType<Codec> {
  constructor() {
    super('stream.video.sfu.models.Codec', [
      {
        no: 1,
        name: 'payload_type',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      { no: 2, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'fmtp_line', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 4,
        name: 'clock_rate',
        kind: 'scalar',
        T: 13 /*ScalarType.UINT32*/,
      },
      {
        no: 5,
        name: 'encoding_parameters',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 6,
        name: 'feedbacks',
        kind: 'scalar',
        repeat: 2 /*RepeatType.UNPACKED*/,
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<Codec>): Codec {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.payloadType = 0;
    message.name = '';
    message.fmtpLine = '';
    message.clockRate = 0;
    message.encodingParameters = '';
    message.feedbacks = [];
    if (value !== undefined)
      reflectionMergePartial<Codec>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Codec,
  ): Codec {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* uint32 payload_type */ 1:
          message.payloadType = reader.uint32();
          break;
        case /* string name */ 2:
          message.name = reader.string();
          break;
        case /* string fmtp_line */ 3:
          message.fmtpLine = reader.string();
          break;
        case /* uint32 clock_rate */ 4:
          message.clockRate = reader.uint32();
          break;
        case /* string encoding_parameters */ 5:
          message.encodingParameters = reader.string();
          break;
        case /* repeated string feedbacks */ 6:
          message.feedbacks.push(reader.string());
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Codec,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* uint32 payload_type = 1; */
    if (message.payloadType !== 0)
      writer.tag(1, WireType.Varint).uint32(message.payloadType);
    /* string name = 2; */
    if (message.name !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.name);
    /* string fmtp_line = 3; */
    if (message.fmtpLine !== '')
      writer.tag(3, WireType.LengthDelimited).string(message.fmtpLine);
    /* uint32 clock_rate = 4; */
    if (message.clockRate !== 0)
      writer.tag(4, WireType.Varint).uint32(message.clockRate);
    /* string encoding_parameters = 5; */
    if (message.encodingParameters !== '')
      writer
        .tag(5, WireType.LengthDelimited)
        .string(message.encodingParameters);
    /* repeated string feedbacks = 6; */
    for (let i = 0; i < message.feedbacks.length; i++)
      writer.tag(6, WireType.LengthDelimited).string(message.feedbacks[i]);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Codec
 */
export const Codec = new Codec$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ICETrickle$Type extends MessageType<ICETrickle> {
  constructor() {
    super('stream.video.sfu.models.ICETrickle', [
      {
        no: 1,
        name: 'peer_type',
        kind: 'enum',
        T: () => ['stream.video.sfu.models.PeerType', PeerType, 'PEER_TYPE_'],
      },
      {
        no: 2,
        name: 'ice_candidate',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 3, name: 'session_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<ICETrickle>): ICETrickle {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.peerType = 0;
    message.iceCandidate = '';
    message.sessionId = '';
    if (value !== undefined)
      reflectionMergePartial<ICETrickle>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ICETrickle,
  ): ICETrickle {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* stream.video.sfu.models.PeerType peer_type */ 1:
          message.peerType = reader.int32();
          break;
        case /* string ice_candidate */ 2:
          message.iceCandidate = reader.string();
          break;
        case /* string session_id */ 3:
          message.sessionId = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ICETrickle,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* stream.video.sfu.models.PeerType peer_type = 1; */
    if (message.peerType !== 0)
      writer.tag(1, WireType.Varint).int32(message.peerType);
    /* string ice_candidate = 2; */
    if (message.iceCandidate !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.iceCandidate);
    /* string session_id = 3; */
    if (message.sessionId !== '')
      writer.tag(3, WireType.LengthDelimited).string(message.sessionId);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.ICETrickle
 */
export const ICETrickle = new ICETrickle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TrackInfo$Type extends MessageType<TrackInfo> {
  constructor() {
    super('stream.video.sfu.models.TrackInfo', [
      { no: 1, name: 'track_id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 2,
        name: 'track_type',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.TrackType',
          TrackType,
          'TRACK_TYPE_',
        ],
      },
      {
        no: 5,
        name: 'layers',
        kind: 'message',
        repeat: 1 /*RepeatType.PACKED*/,
        T: () => VideoLayer,
      },
      { no: 6, name: 'mid', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 7, name: 'dtx', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 8, name: 'stereo', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 9, name: 'red', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
      { no: 10, name: 'muted', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
  create(value?: PartialMessage<TrackInfo>): TrackInfo {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.trackId = '';
    message.trackType = 0;
    message.layers = [];
    message.mid = '';
    message.dtx = false;
    message.stereo = false;
    message.red = false;
    message.muted = false;
    if (value !== undefined)
      reflectionMergePartial<TrackInfo>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: TrackInfo,
  ): TrackInfo {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string track_id */ 1:
          message.trackId = reader.string();
          break;
        case /* stream.video.sfu.models.TrackType track_type */ 2:
          message.trackType = reader.int32();
          break;
        case /* repeated stream.video.sfu.models.VideoLayer layers */ 5:
          message.layers.push(
            VideoLayer.internalBinaryRead(reader, reader.uint32(), options),
          );
          break;
        case /* string mid */ 6:
          message.mid = reader.string();
          break;
        case /* bool dtx */ 7:
          message.dtx = reader.bool();
          break;
        case /* bool stereo */ 8:
          message.stereo = reader.bool();
          break;
        case /* bool red */ 9:
          message.red = reader.bool();
          break;
        case /* bool muted */ 10:
          message.muted = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: TrackInfo,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string track_id = 1; */
    if (message.trackId !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.trackId);
    /* stream.video.sfu.models.TrackType track_type = 2; */
    if (message.trackType !== 0)
      writer.tag(2, WireType.Varint).int32(message.trackType);
    /* repeated stream.video.sfu.models.VideoLayer layers = 5; */
    for (let i = 0; i < message.layers.length; i++)
      VideoLayer.internalBinaryWrite(
        message.layers[i],
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* string mid = 6; */
    if (message.mid !== '')
      writer.tag(6, WireType.LengthDelimited).string(message.mid);
    /* bool dtx = 7; */
    if (message.dtx !== false) writer.tag(7, WireType.Varint).bool(message.dtx);
    /* bool stereo = 8; */
    if (message.stereo !== false)
      writer.tag(8, WireType.Varint).bool(message.stereo);
    /* bool red = 9; */
    if (message.red !== false) writer.tag(9, WireType.Varint).bool(message.red);
    /* bool muted = 10; */
    if (message.muted !== false)
      writer.tag(10, WireType.Varint).bool(message.muted);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.TrackInfo
 */
export const TrackInfo = new TrackInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Error$Type extends MessageType<Error> {
  constructor() {
    super('stream.video.sfu.models.Error', [
      {
        no: 1,
        name: 'code',
        kind: 'enum',
        T: () => [
          'stream.video.sfu.models.ErrorCode',
          ErrorCode,
          'ERROR_CODE_',
        ],
      },
      { no: 2, name: 'message', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'should_retry', kind: 'scalar', T: 8 /*ScalarType.BOOL*/ },
    ]);
  }
  create(value?: PartialMessage<Error>): Error {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.code = 0;
    message.message = '';
    message.shouldRetry = false;
    if (value !== undefined)
      reflectionMergePartial<Error>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Error,
  ): Error {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* stream.video.sfu.models.ErrorCode code */ 1:
          message.code = reader.int32();
          break;
        case /* string message */ 2:
          message.message = reader.string();
          break;
        case /* bool should_retry */ 3:
          message.shouldRetry = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Error,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* stream.video.sfu.models.ErrorCode code = 1; */
    if (message.code !== 0) writer.tag(1, WireType.Varint).int32(message.code);
    /* string message = 2; */
    if (message.message !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.message);
    /* bool should_retry = 3; */
    if (message.shouldRetry !== false)
      writer.tag(3, WireType.Varint).bool(message.shouldRetry);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Error
 */
export const Error = new Error$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClientDetails$Type extends MessageType<ClientDetails> {
  constructor() {
    super('stream.video.sfu.models.ClientDetails', [
      { no: 1, name: 'sdk', kind: 'message', T: () => Sdk },
      { no: 2, name: 'os', kind: 'message', T: () => OS },
      { no: 3, name: 'browser', kind: 'message', T: () => Browser },
      { no: 4, name: 'device', kind: 'message', T: () => Device },
    ]);
  }
  create(value?: PartialMessage<ClientDetails>): ClientDetails {
    const message = globalThis.Object.create(this.messagePrototype!);
    if (value !== undefined)
      reflectionMergePartial<ClientDetails>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: ClientDetails,
  ): ClientDetails {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* stream.video.sfu.models.Sdk sdk */ 1:
          message.sdk = Sdk.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.sdk,
          );
          break;
        case /* stream.video.sfu.models.OS os */ 2:
          message.os = OS.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.os,
          );
          break;
        case /* stream.video.sfu.models.Browser browser */ 3:
          message.browser = Browser.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.browser,
          );
          break;
        case /* stream.video.sfu.models.Device device */ 4:
          message.device = Device.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.device,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: ClientDetails,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* stream.video.sfu.models.Sdk sdk = 1; */
    if (message.sdk)
      Sdk.internalBinaryWrite(
        message.sdk,
        writer.tag(1, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* stream.video.sfu.models.OS os = 2; */
    if (message.os)
      OS.internalBinaryWrite(
        message.os,
        writer.tag(2, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* stream.video.sfu.models.Browser browser = 3; */
    if (message.browser)
      Browser.internalBinaryWrite(
        message.browser,
        writer.tag(3, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* stream.video.sfu.models.Device device = 4; */
    if (message.device)
      Device.internalBinaryWrite(
        message.device,
        writer.tag(4, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.ClientDetails
 */
export const ClientDetails = new ClientDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Sdk$Type extends MessageType<Sdk> {
  constructor() {
    super('stream.video.sfu.models.Sdk', [
      {
        no: 1,
        name: 'type',
        kind: 'enum',
        T: () => ['stream.video.sfu.models.SdkType', SdkType, 'SDK_TYPE_'],
      },
      { no: 2, name: 'major', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 3, name: 'minor', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 4, name: 'patch', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<Sdk>): Sdk {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.type = 0;
    message.major = '';
    message.minor = '';
    message.patch = '';
    if (value !== undefined) reflectionMergePartial<Sdk>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Sdk,
  ): Sdk {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* stream.video.sfu.models.SdkType type */ 1:
          message.type = reader.int32();
          break;
        case /* string major */ 2:
          message.major = reader.string();
          break;
        case /* string minor */ 3:
          message.minor = reader.string();
          break;
        case /* string patch */ 4:
          message.patch = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Sdk,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* stream.video.sfu.models.SdkType type = 1; */
    if (message.type !== 0) writer.tag(1, WireType.Varint).int32(message.type);
    /* string major = 2; */
    if (message.major !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.major);
    /* string minor = 3; */
    if (message.minor !== '')
      writer.tag(3, WireType.LengthDelimited).string(message.minor);
    /* string patch = 4; */
    if (message.patch !== '')
      writer.tag(4, WireType.LengthDelimited).string(message.patch);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Sdk
 */
export const Sdk = new Sdk$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OS$Type extends MessageType<OS> {
  constructor() {
    super('stream.video.sfu.models.OS', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'architecture',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
    ]);
  }
  create(value?: PartialMessage<OS>): OS {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.name = '';
    message.version = '';
    message.architecture = '';
    if (value !== undefined) reflectionMergePartial<OS>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: OS,
  ): OS {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string();
          break;
        case /* string version */ 2:
          message.version = reader.string();
          break;
        case /* string architecture */ 3:
          message.architecture = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: OS,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.name);
    /* string version = 2; */
    if (message.version !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.version);
    /* string architecture = 3; */
    if (message.architecture !== '')
      writer.tag(3, WireType.LengthDelimited).string(message.architecture);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.OS
 */
export const OS = new OS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Browser$Type extends MessageType<Browser> {
  constructor() {
    super('stream.video.sfu.models.Browser', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<Browser>): Browser {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.name = '';
    message.version = '';
    if (value !== undefined)
      reflectionMergePartial<Browser>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Browser,
  ): Browser {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string();
          break;
        case /* string version */ 2:
          message.version = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Browser,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.name);
    /* string version = 2; */
    if (message.version !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.version);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Browser
 */
export const Browser = new Browser$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Device$Type extends MessageType<Device> {
  constructor() {
    super('stream.video.sfu.models.Device', [
      { no: 1, name: 'name', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'version', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
    ]);
  }
  create(value?: PartialMessage<Device>): Device {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.name = '';
    message.version = '';
    if (value !== undefined)
      reflectionMergePartial<Device>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Device,
  ): Device {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string name */ 1:
          message.name = reader.string();
          break;
        case /* string version */ 2:
          message.version = reader.string();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Device,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string name = 1; */
    if (message.name !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.name);
    /* string version = 2; */
    if (message.version !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.version);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Device
 */
export const Device = new Device$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Call$Type extends MessageType<Call> {
  constructor() {
    super('stream.video.sfu.models.Call', [
      { no: 1, name: 'type', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      { no: 2, name: 'id', kind: 'scalar', T: 9 /*ScalarType.STRING*/ },
      {
        no: 3,
        name: 'created_by_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      {
        no: 4,
        name: 'host_user_id',
        kind: 'scalar',
        T: 9 /*ScalarType.STRING*/,
      },
      { no: 5, name: 'custom', kind: 'message', T: () => Struct },
      { no: 6, name: 'created_at', kind: 'message', T: () => Timestamp },
      { no: 7, name: 'updated_at', kind: 'message', T: () => Timestamp },
    ]);
  }
  create(value?: PartialMessage<Call>): Call {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.type = '';
    message.id = '';
    message.createdByUserId = '';
    message.hostUserId = '';
    if (value !== undefined) reflectionMergePartial<Call>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: Call,
  ): Call {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* string type */ 1:
          message.type = reader.string();
          break;
        case /* string id */ 2:
          message.id = reader.string();
          break;
        case /* string created_by_user_id */ 3:
          message.createdByUserId = reader.string();
          break;
        case /* string host_user_id */ 4:
          message.hostUserId = reader.string();
          break;
        case /* google.protobuf.Struct custom */ 5:
          message.custom = Struct.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.custom,
          );
          break;
        case /* google.protobuf.Timestamp created_at */ 6:
          message.createdAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.createdAt,
          );
          break;
        case /* google.protobuf.Timestamp updated_at */ 7:
          message.updatedAt = Timestamp.internalBinaryRead(
            reader,
            reader.uint32(),
            options,
            message.updatedAt,
          );
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: Call,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* string type = 1; */
    if (message.type !== '')
      writer.tag(1, WireType.LengthDelimited).string(message.type);
    /* string id = 2; */
    if (message.id !== '')
      writer.tag(2, WireType.LengthDelimited).string(message.id);
    /* string created_by_user_id = 3; */
    if (message.createdByUserId !== '')
      writer.tag(3, WireType.LengthDelimited).string(message.createdByUserId);
    /* string host_user_id = 4; */
    if (message.hostUserId !== '')
      writer.tag(4, WireType.LengthDelimited).string(message.hostUserId);
    /* google.protobuf.Struct custom = 5; */
    if (message.custom)
      Struct.internalBinaryWrite(
        message.custom,
        writer.tag(5, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp created_at = 6; */
    if (message.createdAt)
      Timestamp.internalBinaryWrite(
        message.createdAt,
        writer.tag(6, WireType.LengthDelimited).fork(),
        options,
      ).join();
    /* google.protobuf.Timestamp updated_at = 7; */
    if (message.updatedAt)
      Timestamp.internalBinaryWrite(
        message.updatedAt,
        writer.tag(7, WireType.LengthDelimited).fork(),
        options,
      ).join();
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.Call
 */
export const Call = new Call$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CallGrants$Type extends MessageType<CallGrants> {
  constructor() {
    super('stream.video.sfu.models.CallGrants', [
      {
        no: 1,
        name: 'can_publish_audio',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 2,
        name: 'can_publish_video',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
      {
        no: 3,
        name: 'can_screenshare',
        kind: 'scalar',
        T: 8 /*ScalarType.BOOL*/,
      },
    ]);
  }
  create(value?: PartialMessage<CallGrants>): CallGrants {
    const message = globalThis.Object.create(this.messagePrototype!);
    message.canPublishAudio = false;
    message.canPublishVideo = false;
    message.canScreenshare = false;
    if (value !== undefined)
      reflectionMergePartial<CallGrants>(this, message, value);
    return message;
  }
  internalBinaryRead(
    reader: IBinaryReader,
    length: number,
    options: BinaryReadOptions,
    target?: CallGrants,
  ): CallGrants {
    let message = target ?? this.create(),
      end = reader.pos + length;
    while (reader.pos < end) {
      let [fieldNo, wireType] = reader.tag();
      switch (fieldNo) {
        case /* bool can_publish_audio */ 1:
          message.canPublishAudio = reader.bool();
          break;
        case /* bool can_publish_video */ 2:
          message.canPublishVideo = reader.bool();
          break;
        case /* bool can_screenshare */ 3:
          message.canScreenshare = reader.bool();
          break;
        default:
          let u = options.readUnknownField;
          if (u === 'throw')
            throw new globalThis.Error(
              `Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`,
            );
          let d = reader.skip(wireType);
          if (u !== false)
            (u === true ? UnknownFieldHandler.onRead : u)(
              this.typeName,
              message,
              fieldNo,
              wireType,
              d,
            );
      }
    }
    return message;
  }
  internalBinaryWrite(
    message: CallGrants,
    writer: IBinaryWriter,
    options: BinaryWriteOptions,
  ): IBinaryWriter {
    /* bool can_publish_audio = 1; */
    if (message.canPublishAudio !== false)
      writer.tag(1, WireType.Varint).bool(message.canPublishAudio);
    /* bool can_publish_video = 2; */
    if (message.canPublishVideo !== false)
      writer.tag(2, WireType.Varint).bool(message.canPublishVideo);
    /* bool can_screenshare = 3; */
    if (message.canScreenshare !== false)
      writer.tag(3, WireType.Varint).bool(message.canScreenshare);
    let u = options.writeUnknownFields;
    if (u !== false)
      (u == true ? UnknownFieldHandler.onWrite : u)(
        this.typeName,
        message,
        writer,
      );
    return writer;
  }
}
/**
 * @generated MessageType for protobuf message stream.video.sfu.models.CallGrants
 */
export const CallGrants = new CallGrants$Type();
